import { apiConfig } from '../config';


export async function getAPI() {
    return await fetch(apiConfig.resourceUri + "CostCalculator/GetOKString", {
        method: 'GET',
        headers: {
            "Content-Type": 'application/json'
        }
    })
};

export async function postCalculation(costData) {
    const response = await fetch(apiConfig.resourceUri + "CostCalculator/AddCostCalculation", {
        method: 'POST',
        headers: {
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(costData)
    });
    const result = await response.json();
    return result;
};