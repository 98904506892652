import React, { useState } from "react";
import { postCalculation } from "../../../../services/cost-calc/CostCalcService";
import { ResponseData, FormData } from "../CalculationForm";
import { useTranslation } from 'react-i18next/';
import { Col, Container, Row, Button } from "react-bootstrap";
import {
  Select,

  MenuItem,
  FormControl,
  TextField,
  Stepper,
  Step,
  StepLabel
} from "@material-ui/core";
import { SetForm, NavigationProps } from "react-hooks-helper";


interface Props {
  formData: FormData;
  setForm: SetForm;
  navigation: NavigationProps;
  handleCalculationChange: (value: ResponseData) => void;
}
export const AmountOfCasesPerYear = ({ formData, setForm, navigation, handleCalculationChange }: Props) => {

  const { t } = useTranslation();
  const amountOfCasesPerYear: number = formData.amountOfCasesPerYear;
  const [displayLoader, setDisplayLoader] = useState(false)
  //const go:(string) => void= navigation.go;
  const [selectedAmount, setData] = React.useState(formData.amountOfCasesPerYear);
  const handleSubmit = (e: any) => {
    setDisplayLoader(true);
    console.log(formData)
    postCalculation(formData).then((response) => {
      handleCalculationChange(response);
      navigation.next();
      //this.props.handleStateChange(false ,response, 2);
    })
      .catch(
        (rp) => { alert(rp) }
      );
  };
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setForm({
      target: {
        name: 'amountOfCasesPerYear',
        value: event.target.value
      }
    });
    setData(event.target.value as number);
  };

  return (<Container>

    {(displayLoader) &&
      <Row className="justify-content-md-center row-margins">
        <Col md><div className="spinner-border spinner-border-lg"></div></Col>
      </Row>}
    {(!displayLoader) && <><Row className="justify-content-md-center row-margins">
      <Col md>
        <Stepper activeStep={3} alternativeLabel>
          <Step key={0}>
            <StepLabel></StepLabel>
          </Step>
          <Step key={1}>
            <StepLabel></StepLabel>
          </Step>
          <Step key={2}>
            <StepLabel></StepLabel>
          </Step>
          <Step key={3}>
            <StepLabel></StepLabel>
          </Step>
        </Stepper>
      </Col>
    </Row>
      <Row className="justify-content-md-center row-margins">
        <Col md={9}>
          <p className="top-text">{t("Common.CasesPerYear")}</p>
        </Col>
      </Row>
      <Row className="justify-content-md-center row-margins">
        <Col md={3} className="row-margins">
          <FormControl variant="outlined"  style={{width:"123px"}}>
            <Select
              value={selectedAmount}
              onChange={handleChange}
            >
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Col>
        <Col md={3} className="row-margins">
          <FormControl variant="outlined">
            <TextField
              label={t("Common.Amount")}
              name="amountOfCasesPerYear"
              value={amountOfCasesPerYear}
              autoComplete="off"
              onChange={setForm}
            />
          </FormControl>
        </Col>
        <Col md={3} className="row-margins">
          <Button className="btn-action" style={{ width: "150px" }}
            onClick={handleSubmit}
          >
            {t("Common.CalculateNow")}
          </Button>
        </Col>
      </Row>
    </>
    }
  </Container>
  );
};
