import React, { Component } from "react";
import { CalculationForm } from "../components/www/cost-calc/CalculationForm";
import { getAPI } from "../services/cost-calc/CostCalcService";
interface IProps {
  isAdmin?: string,
  lang:string
}
export class CalcContainer extends Component<IProps> {

  componentDidMount() {
    getAPI();
  }
  render() {
    return (
      <div>
        
        <CalculationForm lang={this.props.lang} isAdmin={this.props.isAdmin}/>
      </div>
    );
  }
}
