import React, { useState } from 'react'
import {
    Grid,
    TextField,
    Button,
    makeStyles,
    createStyles,
    Theme,
    Select,
    RadioGroup,
    Radio,
    FormControlLabel,
    MenuItem,
} from '@material-ui/core'
import { Formik, Form, FormikProps } from 'formik'
import * as Yup from 'yup'
import ReCAPTCHA from 'react-google-recaptcha';
import { postContact} from "../../../services/contact/ContactService";
import { browserVersion, browserName } from "react-device-detect";
import { useTranslation } from 'react-i18next/';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: '450px',
            display: 'block',
            margin: '0 auto',
        },
        textField: {
            '& > *': {
                width: '100%',
            },
        },
        buttonLook: {
            backgroundColor: "#091C53",
            '&:hover': {
                background: "#091C53",
                color: "#ffffff",
            },
            borderRadius: 4,
            color: "#ffffff",
            width: "100%",
            height: 48,

        },
        title: { textAlign: 'center' },
        successMessage: { color: 'green' },
        errorMessage: { color: 'red' },
    })
)

interface IContactForm {
    role: string
    phone: string
    subject: string
    wayOfContact: string
    body: string
    email: string
    databrowserLanguage: string,
    browserName: string,
    browserVersion: string,
    displayHeight: number,
    displayWidht: number,
    token?:string
}

interface IFormStatus {
    message: string
    type: string
}

interface IFormStatusProps {
    [key: string]: IFormStatus
}

const formStatusProps: IFormStatusProps = {
    success: {
        message: 'Your message was sent successfully.',
        type: 'success',
    },
    error: {
        message: 'Something went wrong. Please try again.',
        type: 'error',
    },
}

export const ContactForm: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation();
    const recaptchaRef = React.useRef<ReCAPTCHA>();
    const [wayOfContactState, setWayOfContactValue] = React.useState(null);
    const [displayFormStatus, setDisplayFormStatus] = useState(false)
    const [formStatus, setFormStatus] = useState<IFormStatus>({
        message: '',
        type: '',
    })
    const handleWayOfContactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWayOfContactValue((event.target as HTMLInputElement).value);
    };
    const sendContactRequest = async (data: IContactForm, resetForm: Function) => {
        try {
            console.log(data);
            data.token = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();

            if (data) {
                
                postContact(data).then((response) => {
                    console.log(response)
                    setFormStatus(formStatusProps.success)
                    resetForm({})
                });
            }
        } catch (error) {
                setFormStatus(formStatusProps.error)
        } finally {
            setDisplayFormStatus(true)
        }
    }

    return (
        <div className={classes.root}>
            <Formik
                initialValues={{
                    role: 'patient',
                    phone: '',
                    subject: '',
                    wayOfContact: '',
                    body: '',
                    email: '',
                    databrowserLanguage:navigator.language,
                    browserName:browserName,
                    browserVersion:browserVersion,
                    displayHeight: window.innerHeight,
                    displayWidht: window.innerWidth,
                }}
                onSubmit={(values: IContactForm, actions) => {                    
                    sendContactRequest(values, actions.resetForm)
                    setTimeout(() => {
                        actions.setSubmitting(false)
                    }, 500)
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email()
                        .required('Enter valid email'),
                    subject: Yup.string().required('Please enter subject'),
                    body: Yup.string().required('Please enter message'),

                })}
            >
                {(props: FormikProps<IContactForm>) => {
                    const {
                        values,
                        touched,
                        errors,
                        handleBlur,
                        handleChange,
                        isSubmitting,
                        setFieldValue,
                    } = props
                    return (
                        <Form>
                            <h1 className={classes.title}>Contact Us</h1>
                            <Grid
                                container
                                justify="space-around"
                                direction="row"
                                spacing={2}
                            >
                                <Grid
                                    item
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    xs={10}
                                    className={classes.textField}
                                >
                                    <Select
                                        name="role"
                                        id="role"
                                        label="Select your role"
                                        value={values.role}
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        variant="outlined"
                                    >
                                        <MenuItem value="patient">{t("ALL.Dictionary.Role.Client")}</MenuItem>
                                        <MenuItem value="employer">{t("ALL.Dictionary.Role.Employer")}</MenuItem>
                                        <MenuItem value="case manager">{t("ALL.Dictionary.Role.CaseManager")}</MenuItem>
                                        <MenuItem value="doctor">{t("ALL.Dictionary.Role.Doctor")}</MenuItem>
                                        <MenuItem value="insurer">{t("ALL.Dictionary.Role.Insurer")}</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    xs={10}
                                    className={classes.textField}
                                >
                                    <TextField
                                        name="email"
                                        id="email"
                                        label="Email"
                                        value={values.email}
                                        type="email"
                                        helperText={
                                            errors.email && touched.email
                                                ? errors.email
                                                : 'Enter email'
                                        }
                                        error={
                                            errors.email && touched.email
                                                ? true
                                                : false
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    xs={10}
                                    className={classes.textField}
                                >
                                    <TextField
                                        name="phone"
                                        id="phone"
                                        label="Phone number (Optional)"
                                        value={values.phone}
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    xs={10}
                                    className={classes.textField}
                                >
                                    <RadioGroup aria-label="wayOfContact" name="wayOfContact" value={wayOfContactState} onChange={e => {
                                        handleWayOfContactChange(e)
                                        setFieldValue('wayOfContact', e.target.value)
                                    }}>
                                        <FormControlLabel value="email" control={<Radio color="default" size="small" />} label="Email" />
                                        <FormControlLabel value="phone" control={<Radio color="default" size="small" />} label="Phone" disabled={!(values.phone) ? true : false} />
                                    </RadioGroup>
                                </Grid>
                                <Grid
                                    item
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    xs={10}
                                    className={classes.textField}
                                >
                                    <TextField
                                        name="subject"
                                        id="subject"
                                        label="Subject"
                                        value={values.subject}
                                        type="text"
                                        helperText={
                                            errors.subject && touched.subject
                                                ? errors.subject
                                                : 'Enter subject'
                                        }
                                        error={
                                            errors.subject && touched.subject
                                                ? true
                                                : false
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    xs={10}
                                    className={classes.textField}
                                >
                                    <TextField
                                        name="body"
                                        id="body"
                                        label="Write down your message"
                                        value={values.body}
                                        multiline
                                        rows={10}
                                        helperText={
                                            errors.body && touched.body
                                                ? errors.body
                                                : 'Enter message'
                                        }
                                        error={
                                            errors.body && touched.body
                                                ? true
                                                : false
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    xs={10}

                                >
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6LclFvgZAAAAAInbxMNLmXEVh0FDTjn8huFfiLWl"
                                        size="invisible"
                                    />
                                    <Button className={classes.buttonLook}
                                        type="submit"
                                        variant="contained"
                                        disabled={isSubmitting}
                                    >
                                        Send a message
                                    </Button>
                                    {displayFormStatus && (
                                        <div className="formStatus">
                                            {formStatus.type === 'error' ? (
                                                <p
                                                    className={
                                                        classes.errorMessage
                                                    }
                                                >
                                                    {formStatus.message}
                                                </p>
                                            ) : formStatus.type ===
                                                'success' ? (
                                                        <p
                                                            className={
                                                                classes.successMessage
                                                            }
                                                        >
                                                            {formStatus.message}
                                                        </p>
                                                    ) : null}
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

