import React, { Component } from 'react';
import { Route } from 'react-router';
import { CalcContainer } from './containers/CalcContainer';
import { ContactForm } from './components/www/contact/ContactForm';
import './www-style.css'

export class WebPageApp extends Component {
    render() {
        return (
            <div>
                <Route exact path="/www/calculation/:lang/:isAdmin?" render={(props) => <CalcContainer lang={props.match.params.lang} isAdmin={props.match.params.isAdmin}/>} />
                <Route path='/www/contact' component={ContactForm} />
            </div>
        );
    }
}
