import React, { FunctionComponent, useEffect } from "react";
import { Step, useForm, useStep } from "react-hooks-helper";
import { browserVersion, browserName } from "react-device-detect";
import "./costs.css";
import { MedicalReportInterval } from "./steps/MedicalReportInterval";
import { Branch } from "./steps/Branch";
import { IncapacityOfWorkInMonth } from "./steps/IncapacityOfWorkInMonth";
import { AmountOfCasesPerYear } from "./steps/AmountOfCasesPerYear";
import { Submit } from "./steps/Submit";
import { useTranslation } from 'react-i18next/';
import { Col, Container, Row } from "react-bootstrap";
import { isNull } from "node:util";
import i18n from "../../../i18n";

const resultDefoult = {
  calcResult: {
    dailyAllowanceLeft: 12,
    dailyAllowanceRight: 12,
    feeDoctorLeft: 12,
    feeCoordinatorLeft: 12,
    feeDoctorRight: 12,
    feeCoordinatorRight: 12,
    feeLeft: 12,
    feeRight: 12,
    feeLicense: 12,
    savings: 12,
    timeRight: 12,
    timeLeft: 12
  },
  barWidth: {
    allowanceLeft: 12,
    allowanceRight: 14,
    feeDoctorLeft: 7,
    feeCoordinatorLeft: 5,
    feeDoctorRight: 7,
    feeCoordinatorRight: 5,
    feeLicense: 13,
    timeRight: 11,
    timeLeft: 33
  }
};
const steps: Array<Step> = [
  { id: "branch" },
  { id: "medicalReportInterval" },
  { id: "incapacityOfWorkInMonth" },
  { id: "amountOfCasesPerYear" },
  { id: "submit" },
];

export interface FormData {
  browserLanguage: string,
  browserName: string,
  browserVersion: string,
  displayHeight: number,
  displayWidht: number,
  branchShorterIntegration: number,
  branch: string,
  branchAverageAnnualWage: number,
  medicalReportInterval: number,
  incapacityOfWorkInMonth: number,
  amountOfCasesPerYear: number,
}
export interface ResponseData {
  calcResult: {
    dailyAllowanceLeft: number,
    dailyAllowanceRight: number,
    feeDoctorLeft: number,
    feeCoordinatorLeft: number,
    feeDoctorRight: number,
    feeCoordinatorRight: number,
    feeLeft: number,
    feeRight: number,
    feeLicense: number,
    savings: number,
    timeRight: number,
    timeLeft: number
  }
  barWidth?: {
    allowanceLeft: number,
    allowanceRight: number,
    feeDoctorLeft: number,
    feeCoordinatorLeft: number,
    feeDoctorRight: number,
    feeCoordinatorRight: number,
    feeLicense: number,
    timeRight: number,
    timeLeft: number
  }
}
export interface Response {
  response: ResponseData,
  header: string,
}
interface IProps {
  isAdmin?: string,
  lang:string
}
export const CalculationForm: React.FC<IProps> = ({lang,isAdmin}) => {
  const [formData, setForm] = useForm<FormData>({
    browserLanguage: navigator.language,
    browserName: browserName,
    browserVersion: browserVersion,
    displayHeight: window.innerHeight,
    displayWidht: window.innerWidth,
    branchShorterIntegration: 21,
    branch: "procucction",
    branchAverageAnnualWage: 76800,
    medicalReportInterval: 60,
    incapacityOfWorkInMonth: 12,
    amountOfCasesPerYear: 10,
  });
  const { t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(lang)
  }, []);
  const { step, navigation } = useStep({ initialStep: 0, steps });

  const [calculation, setCalculation] = React.useState<Response>({
    response: resultDefoult,
    header: "start",
  });
  function handleCalculationChange(data: ResponseData) {
    setCalculation({
      response: data,
      header: "submit",
    });
    console.log(calculation);
  }

  const props = { formData, setForm, navigation };
  let component = null;
  switch (step["id"]) {
    case "branch":
      component = <Branch {...props} />;
      break;
    case "medicalReportInterval":
      component = <MedicalReportInterval {...props} />;
      break;
    case "incapacityOfWorkInMonth":
      component = <IncapacityOfWorkInMonth {...props} />;
      break;
    case "amountOfCasesPerYear":
      component = (
        <AmountOfCasesPerYear
          formData={formData}
          navigation={navigation}
          setForm={setForm}
          handleCalculationChange={handleCalculationChange}
        />
      );
      break;
    case "submit":
      component = (
        <Submit formData={formData} response={calculation.response} navigation={navigation} isAdmin={isAdmin!==undefined}/>
      );
      break;
    default:
      component = <div></div>;
      break;
  }

  return (
    <Container>
      <Row>
        <Col md={12} className="top">
            <p>{calculation.header === "start" ? t("Common.YourSavings") : t("Common.SavingFigures")}</p>
        </Col>
      </Row>
      <Row>
        <Col md={12}>{component}</Col>
      </Row>
    </Container>
  );
};
