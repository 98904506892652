import React from "react";
import { Col, Container, Row,Button } from "react-bootstrap";
import {
  Select,
  MenuItem,
  FormControl,
  TextField,
  Stepper,
  Step,
  StepLabel
} from "@material-ui/core";
import { SetForm, NavigationProps } from "react-hooks-helper";
import { FormData } from "../CalculationForm";
import { useTranslation } from 'react-i18next/';

interface Props {
  formData: FormData;
  setForm: SetForm;
  navigation: NavigationProps;
}
export const Branch = ({ formData, setForm, navigation }: Props) => {
  const { branchAverageAnnualWage } = formData;
  const { t } = useTranslation();
  const [selectedBranch, setData] = React.useState(formData.branch);

  const branchMapping = [
    { id: "gastronomy", wage: 52800, integrationPrec: 20 },
    { id: "teacher", wage: 102000, integrationPrec: 44 },
    { id: "doctor", wage: 200000, integrationPrec: 50 },
    { id: "social branch", wage: 75600, integrationPrec: 33 },
    { id: "procucction", wage: 76800, integrationPrec: 21 },
    { id: "office", wage: 108000, integrationPrec: 12 },
    { id: "installation", wage: 76800, integrationPrec: 33 },
    { id: "management", wage: 300000, integrationPrec: 21 },
    { id: "other", wage: 0, integrationPrec: 21 },
  ];

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setForm({
      target: {
        name: 'branch',
        value: event.target.value
      }
    });
    setForm({
      target: {
        name: 'branchAverageAnnualWage',
        value: branchMapping.filter(
          (branchMapping) => branchMapping.id === event.target.value
        )[0].wage
      }
    });
    setForm({
      target: {
        name: 'branchShorterIntegration',
        value: branchMapping.filter(
          (branchMapping) => branchMapping.id === event.target.value
        )[0].integrationPrec
      }
    });
    setData(event.target.value as string);
  };
  return (<Container>
    <Row className="justify-content-md-center row-margins">
        <Col md>
          <Stepper activeStep={0} alternativeLabel>
            <Step key={0}>
            <StepLabel></StepLabel>
            </Step>
            <Step key={1}>
            <StepLabel></StepLabel>
            </Step>
            <Step key={2}>
            <StepLabel></StepLabel>
            </Step>
            <Step key={3}>
            <StepLabel></StepLabel>
            </Step>
          </Stepper>
          </Col>
      </Row>
      <Row className="justify-content-md-center row-margins">
        <Col md={9}>
          <p className="top-text">{t("Common.BranchAvarage")}</p>
          </Col>
      </Row>
      <Row className="justify-content-md-center row-margins">
        <Col md={3} className="row-margins">
          <FormControl variant="outlined">
            <Select
              id="branch"
              value={selectedBranch}
              onChange={handleChange}
              label={t("Branch.BranchName")}
              name="branch"
            >
              <MenuItem value="gastronomy">{t("Branch.Gastronomy")}</MenuItem>
              <MenuItem value="teacher">{t("Branch.Teacher")}</MenuItem>
              <MenuItem value="doctor">{t("Branch.Doctor")}</MenuItem>
              <MenuItem value="social branch">{t("Branch.Social")}</MenuItem>
              <MenuItem value="procucction">{t("Branch.Production")}</MenuItem>
              <MenuItem value="office">{t("Branch.Office")}</MenuItem>
              <MenuItem value="installation">{t("Branch.Installation")}</MenuItem>
              <MenuItem value="management">{t("Branch.Management")}</MenuItem>
              <MenuItem value="other">{t("Branch.Other")}</MenuItem>
            </Select>
          </FormControl>
          </Col>
        <Col md={3} className="row-margins">
          <FormControl variant="outlined">
            <TextField
              label={t("Common.Wage")}
              name="branchAverageAnnualWage"
              value={branchAverageAnnualWage}
              autoComplete="off"
              onChange={setForm}
            />
          </FormControl>
          </Col>
          <Col md={3} className="row-margins">
          <Button className="btn-action"
            onClick={() => navigation.next()}>
            {t("Common.NextStep")}
            </Button>
            </Col>
      </Row>
</Container>
  );
};
