import React from "react";
import { Col, Container, Row,Button } from "react-bootstrap";
import {
  Select,  
  MenuItem,
  FormControl,
  Stepper,
  Step,
  StepLabel
} from "@material-ui/core";
import { SetForm, NavigationProps } from "react-hooks-helper";
import { FormData } from "../CalculationForm";
import { useTranslation } from 'react-i18next/';


interface Props {
  formData: FormData;
  setForm: SetForm;
  navigation: NavigationProps;
}
export const IncapacityOfWorkInMonth = ({ formData, setForm, navigation }:Props) => {

  const { t } = useTranslation();
  const [selectedValue, setData] = React.useState(formData.incapacityOfWorkInMonth)
  const handleChange = (event:React.ChangeEvent<{value:unknown}>) => {
    setForm({
      target: {
        name: 'incapacityOfWorkInMonth',
        value: event.target.value
      }
    });
    setData(event.target.value as number);
  };
  return (<Container>
    <Row className="justify-content-md-center row-margins">
      <Col md>
          <Stepper activeStep={2} alternativeLabel>
            <Step key={0}>
            <StepLabel></StepLabel>
            </Step>
            <Step key={1}>
            <StepLabel></StepLabel>
            </Step>
            <Step key={2}>
            <StepLabel></StepLabel>
            </Step>
            <Step key={3}>
            <StepLabel></StepLabel>
            </Step>
          </Stepper>
          </Col>
    </Row>
    <Row className="justify-content-md-center row-margins">
    <Col md={9}>
          <p className="top-text">{t("Common.IncapacityMonth")}</p>
          </Col>
    </Row>
    <Row className="justify-content-md-center row-margins">
      <Col md={4} className="row-margins">
          <FormControl variant="outlined"  style={{width:"123px"}}>
            <Select
              id="incapacityOfWorkInMonth"
              value={selectedValue}
              onChange={handleChange}
              name="incapacityOfWorkInMonth"
            >
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={12}>12</MenuItem>
              <MenuItem value={18}>18</MenuItem>
              <MenuItem value={24}>24</MenuItem>
              <MenuItem value={36}>36</MenuItem>
            </Select>
          </FormControl>
          </Col>
        <Col md={5} className="row-margins">
          <Button className="btn-action"
            onClick={() => navigation.next()}
          >
            {t("Common.NextStep")}
          </Button>
          </Col>
    </Row>
</Container>
  );
};
