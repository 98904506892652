import React, { Component } from 'react';
import { WebPageApp } from './WebPageApp';

export class App extends Component{
    render() {
        return (<WebPageApp/>         
        );
    }
}

