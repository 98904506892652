import React from "react";
import { NavigationProps } from "react-hooks-helper";
import { ResponseData, FormData } from "../CalculationForm";
import { faBusinessTime, faClock, faSackDollar } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next/';
import { Col, Container, Row } from "react-bootstrap";
import logo from '../../../../assets/images/logo.png';
import logoMobile from '../../../../assets/images/mobile.png';
import drop from '../../../../assets/images/drop.svg';
interface Props {
  response: ResponseData;
  navigation: NavigationProps;
  formData: FormData;
  isAdmin: boolean
}
export const Submit = ({ isAdmin, formData, response, navigation }: Props) => {
  const { t } = useTranslation();
  return (<Container>
    <Row className="justify-content-md-center row-margins">
      <Col xs={3}>
        <span className="head-text box">{t("Common.StandardCase")}</span>
      </Col>
      <Col xs={3}></Col>
      <Col xs={3}>
        <Row>
          <Col>
            <img src={logo} className="appIcon" alt="logo" />
            <img src={logoMobile} className="appIconMobile" alt="logo" />
          </Col>
        </Row>
      </Col>
      <Col xs={3}></Col>
    </Row>
    <Row className="justify-content-md-center row-margins">
      <Col xs={3}>
        <div className="p-4 mb-2 bg-allowance-left text-white" style={{ width: `${response.barWidth.allowanceLeft}%` }}></div>
      </Col>
      <Col xs={3} className="text-allowance">
        <div>
          <div><FontAwesomeIcon icon={faBusinessTime} className="fas fa-2x" /></div>
          <div>{t("Common.SavingsDailyAllowance")}</div>
        </div>
      </Col>
      <Col xs={3} className="mobile-padding-right">
        <div className="p-4 mb-2 bg-allowance-right text-white" style={{ width: `${response.barWidth.allowanceRight}%` }}></div>
      </Col>
      <Col xs={3} className="mobile-padding-left">
        <div className="tear">
          <img src={drop} className="drop" alt="logo" />
          <p className="tear-number">{`${response.calcResult.savings}%`}</p>
          <p className="tear-text">{t("Common.SavingsCost")}</p>
        </div>
      </Col>
    </Row>
    <Row className="justify-content-md-center row-margins">
      <Col xs={3}>
        <div className="p-4 mb-2 bg-time-left text-white" style={{ width: `${response.barWidth.timeLeft}%` }}></div>
      </Col>
      <Col xs={3} className="text-time">
        <div>
          <div><FontAwesomeIcon icon={faClock} className="fas fa-2x" /></div>
          <div>{t("Common.SavingsTime")}</div>
        </div>
      </Col>
      <Col xs={3}>
        <div className="p-4 mb-2 bg-time-right text-white" style={{ width: `${response.barWidth.timeRight}%` }}></div>
      </Col>
      <Col xs={3}></Col>
    </Row>
    <Row className="justify-content-md-center row-margins">
      <Col xs={3}>
        <div className="p-4 mb-2 bg-fee-left-coordinator text-white" style={{ width: `${response.barWidth.feeCoordinatorLeft}%` }}></div>
        <div className="p-4 mb-2 bg-fee-left-doctor text-white" style={{ width: `${response.barWidth.feeDoctorLeft}%` }}></div>
      </Col>
      <Col xs={3} className="text-fee">
        <div>
          <div><FontAwesomeIcon icon={faSackDollar} className="fas fa-2x" /></div>
          <div className='legend'>
            <div className='legend-title'>{t("Common.FeesLicense")}</div>
            <div className='legend-scale'>
              <ul className='legend-labels'>
                <li><span className="fee bg-fee-right-coordinator"></span>{t("Common.FeeCoordinator")}</li>
                <li><span className="fee bg-fee-right-doctor"></span>{t("Common.FeeDoctor")}</li>
                <li><span className="fee bg-fee-license"></span>{t("Common.FeeLicense")}</li>

              </ul>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={3}>
        <div className="box">
          <div className="p-4 mb-2 bg-fee-right-coordinator text-white" style={{ width: `${response.barWidth.feeCoordinatorRight}%`, float: "left" }}></div>
          <div className="p-4 mb-2 bg-fee-right-doctor text-white" style={{ width: `${response.barWidth.feeDoctorRight}%`, float: "left" }}></div>
          <div className="p-4 mb-2 bg-fee-license text-white" style={{ width: `${response.barWidth.feeLicense}%` }}></div>
        </div>
      </Col>
      <Col xs={3}></Col>
    </Row>
    {isAdmin && <>
      <hr></hr>
      {t("Common.SavingsSummary")}
      <hr></hr>
      <Row className="justify-content-md-center">
        <Col xs={3}>
        </Col>
        <Col xs={3}>
          <b>{t("Common.StandardCase")}</b>
        </Col>
        <Col xs={3}>
        <b>CaseDesk</b>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={3}>
          {t("Common.DailyAllowence")}
        </Col>
        <Col xs={3}>
          {response.calcResult.dailyAllowanceLeft}
        </Col>
        <Col xs={3}>
          {response.calcResult.dailyAllowanceRight}
        </Col>
      </Row>
      <Row className="justify-content-md-center ">
        <Col xs={3}>
        {t("Common.Time")}
        </Col>
        <Col xs={3}>
          {response.calcResult.timeLeft}
        </Col>
        <Col xs={3}>
          {response.calcResult.timeRight}
        </Col>
      </Row>
      <Row className="justify-content-md-center ">
        <Col xs={3}>
        {t("Common.FeeDoctor")}
        </Col>
        <Col xs={3}>
          {response.calcResult.feeDoctorLeft}
        </Col>
        <Col xs={3}>
          {response.calcResult.feeDoctorRight}
        </Col>
      </Row>
      <Row className="justify-content-md-center ">
        <Col xs={3}>
        {t("Common.FeeCoordinator")}
        </Col>
        <Col xs={3}>
          {response.calcResult.feeCoordinatorLeft}
        </Col>
        <Col xs={3}>
          {response.calcResult.feeCoordinatorRight}
        </Col>
      </Row>
      <Row className="justify-content-md-center ">
        <Col xs={3}>
          {t("Common.FeeTotal")}
        </Col>
        <Col xs={3}>
          {response.calcResult.feeLeft}
        </Col>
        <Col xs={3}>
          {response.calcResult.feeRight}
        </Col>
      </Row>
      <Row className="justify-content-md-center ">
        <Col xs={3}>
        {t("Common.FeeLicense")}
        </Col>
        <Col xs={3}>
          -
        </Col>
        <Col xs={3}>
          {response.calcResult.feeLicense}
        </Col>
      </Row>
    </>
    }
  </Container>
  );
};
