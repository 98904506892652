import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import {
  Select,
  MenuItem,
  FormControl,
  Stepper,
  Step,
  StepLabel
} from "@material-ui/core";
import { SetForm, NavigationProps } from "react-hooks-helper";
import { FormData } from "../CalculationForm";
import { useTranslation } from 'react-i18next/';
interface Props {
  formData: FormData;
  setForm: SetForm;
  navigation: NavigationProps;
}

export const MedicalReportInterval = ({ formData, setForm, navigation }: Props) => {

  const { t } = useTranslation();
  const medicalReportInterval: number = formData.medicalReportInterval;
  const [selectedValue, setData] = React.useState(medicalReportInterval)
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setForm({
      target: {
        name: 'medicalReportInterval',
        value: event.target.value
      }
    });
    setData(event.target.value as number);
  };
  console.log(formData)
  return (<Container>
    <Row className="justify-content-md-center row-margins">
      <Col md>
        <Stepper activeStep={1} alternativeLabel>
          <Step key={0}>
            <StepLabel></StepLabel>
          </Step>
          <Step key={1}>
            <StepLabel></StepLabel>
          </Step>
          <Step key={2}>
            <StepLabel></StepLabel>
          </Step>
          <Step key={3}>
            <StepLabel></StepLabel>
          </Step>
        </Stepper>
      </Col>
    </Row>
    <Row className="justify-content-md-center row-margins">
    <Col md={9}>
          <p className="top-text">{t("Common.MedicalReportIntervalDays")}</p>
      </Col>
    </Row>
    <Row className="justify-content-md-center row-margins">
      <Col md={4} className="row-margins" >
        <FormControl variant="outlined" style={{width:"123px"}}>
          <Select
            id="medicalReportInterval"
            value={selectedValue}
            onChange={handleChange}
            name="medicalReportInterval"
          >
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={60}>60</MenuItem>
            <MenuItem value={90}>90</MenuItem>
          </Select>
        </FormControl>
      </Col>
      <Col md={5} className="row-margins">
        <Button className="btn-action"
          onClick={() => navigation.next()}>
          {t("Common.NextStep")}
        </Button>
      </Col>
    </Row>
  </Container>
  );
};
