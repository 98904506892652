import { apiConfig } from '../config';


export async function postContact(contactData) {
    const response = await fetch(apiConfig.resourceUri + "Contact/SendContactRequest", {
        method: 'POST',
        headers: {
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(contactData)
    });
    const result = await response.json();
    return result;
};

